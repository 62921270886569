import React from 'react';
import { useGlobal } from 'reactn';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import api from './../service';
import Cookies from 'js-cookie';


const Login = (props) => {

  let [token, setToken] = useGlobal('token');
  let [user, setUser] = useGlobal('user');


  const auth = async ({ username, password }) => {
    let params = {
      email: username,
      password: password,
    };
    delete api.headers['Authorization'];
    await api
      .post('/rest/login', params)
      .then(async ({ data }) => {
        setToken(data.result.token);
        setUser(data.result);
        localStorage.setItem('user', JSON.stringify(data.result));
        localStorage.setItem('token', JSON.stringify(data.result.token));
        api.setHeader('Authorization', 'Bearer ' + data.result.token);
        Cookies.set('token', data.result.token);
        props.history.push("/user");
      })
      .catch((e) => {
        alert(e,'Email veya Şifre Hatalı');
      })
  };

  const onFinish = (values) => {
    auth(values);
  };

  return (
    <div className="login-wrap ">
      <div className="form ">
        <div
          className="form-back"
        >
          <div className="login-image d-flex justify-content-center align-items-center">
            <img alt="" src="/logo.svg" />
          </div>
          <Form
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
              marginBottom: '20px',
            }}
            name="normal_login"
            className="login-form"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
                value=""
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Şifre"
                value=""
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="light"
                htmlType="submit"
                className="login-form-button"
                id="1"
                style={{
                  color: '#000',
                  borderColor: '#b2cf97',
                  marginBottom: '20px',
                }}
              >
                Giriş Yap
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
