import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useGlobal } from 'reactn';
import {
  MdMeetingRoom,
  MdSupervisedUserCircle,
  MdEvent,
  MdAddLocationAlt,
  MdEditCalendar,
  MdCircleNotifications,
  MdOutlineShareLocation,
  MdEventAvailable,
  MdNotificationImportant,
  MdEventSeat,
  MdSettingsSuggest,
  MdPlace

} from 'react-icons/md';

const { Sider } = Layout;

const SideMenu = ({ testFunc }) => {
  const [collapsed, setCollapsed] = useGlobal('collapsed');
  let [isSmall] = useGlobal('isSmall');
  let [modules] = useGlobal('modules');

  // function tabTitle(titleName) {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  //   document.title = 'Elektron - ' + titleName;
  // }

  const getMenu = () => {
    function getItem(label, key, icon, children, type) {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }

    const arr = [];

    const arrOther = [
      {
        type: 'user',
        name: 'Kullanıcılar',
        order: 1,
        icon: <MdSupervisedUserCircle />,
      },
      {
        type: 'room',
        name: 'Toplantı Odaları',
        order: 2,
        icon: <MdMeetingRoom />,
      },
      {
        type: 'room-reservation',
        name: 'Oda Rezervasyonları',
        order: 3,
        icon: <MdEventSeat />,
      },
      {
        type: 'event-reservation',
        name: 'Etkinlik Rezervasyonları',
        order: 4,
        icon: <MdEventAvailable />,
      },
      {
        type: 'event',
        name: 'Etkinlikler',
        order: 5,
        icon: <MdEvent />,
      },
      {
        type: 'event-location',
        name: 'Etkinlik Lokasyonu',
        order: 6,
        icon: <MdAddLocationAlt />,
      },
      {
        type: 'room-location',
        name: 'Oda Lokasyonu',
        order: 7,
        icon: <MdOutlineShareLocation />,
      },
      {
        type: 'notification',
        name: 'Bildirimler',
        order: 8,
        icon: <MdCircleNotifications />,
      }, 
      {
        type: 'static-notification',
        name: 'Sabit Bildirimler',
        order: 9,
        icon: <MdNotificationImportant />,
      },
      {
        type: "settings",
        name: "Ayarlar",
        order: 10,
        icon: <MdSettingsSuggest />,
      },
      {
        type: 'place',
        name: 'Binalar',
        order: 11,
        icon: <MdPlace />,
      }
    ];

    // grup olmayanları döner
    for (const item of arrOther) {
      arr.push(
        getItem(
          <Link to={'/' + item.type}>{item.name}</Link>,
          item.order,
          item.icon,
        ),
      );
    }
    return arr;
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      theme="light"
      width={isSmall ? '150' : '260'}
    >
      <div className="logo-wrap">
        <div className="logo">
          <a href="/">
            <img alt="" src="/logo.svg" />
          </a>
        </div>
        <div className="logomini">
          <a href="/">
            <img alt="" src="/logo.svg" />
          </a>
        </div>
      </div>

      <Menu
        defaultSelectedKeys={['1']}
        // defaultOpenKeys={['0']}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
        items={getMenu()}
      />
    </Sider>
  );
};

export default SideMenu;
