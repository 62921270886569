export function turkishSort(a, b) {
  const alphabet =
    'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
  if (a.length === 0 || b.length === 0) {
    return a.length - b.length;
  }
  for (var i = 0; i < a.length && i < b.length; i++) {
    var ai = alphabet.indexOf(a[i]);
    var bi = alphabet.indexOf(b[i]);
    if (ai !== bi) {
      return ai - bi;
    }
  }
}

const reservationTimes = [
  {
    start: '09:00',
    end: '09:30',
    active: true,
    expired: true,
  },
  {
    start: '09:30',
    end: '10:00',
    active: true,
    expired: true,
  },
  {
    start: '10:00',
    end: '10:30',
    active: true,
    expired: true,
  },
  {
    start: '10:30',
    end: '11:00',
    active: true,
    expired: true,
  },
  {
    start: '11:00',
    end: '11:30',
    active: true,
    expired: true,
  },
  {
    start: '11:30',
    end: '12:00',
    active: true,
    expired: true,
  },
  {
    start: '12:00',
    end: '12:30',
    active: true,
    expired: true,
  },
  {
    start: '12:30',
    end: '13:00',
    active: true,
    expired: true,
  },
  {
    start: '13:00',
    end: '13:30',
    active: true,
    expired: true,
  },
  {
    start: '13:30',
    end: '14:00',
    active: true,
  },
  {
    start: '14:00',
    end: '14:30',
    active: true,
  },
  {
    start: '14:30',
    end: '15:00',
    active: true,
  },
  {
    start: '15:00',
    end: '15:30',
    active: true,
  },
  {
    start: '15:30',
    end: '16:00',
    active: true,
  },
  {
    start: '16:00',
    end: '16:30',
    active: true,
  },
  {
    start: '16:30',
    end: '17:00',
    active: true,
  },
  {
    start: '17:00',
    end: '17:30',
    active: true,
  },
  {
    start: '17:30',
    end: '18:00',
    active: true,
  },
  {
    start: '18:00',
    end: '18:30',
    active: true,
  },
  {
    start: '18:30',
    end: '19:00',
    active: true,
  },
  {
    start: '19:00',
    end: '19:30',
    active: true,
  },
  {
    start: '19:30',
    end: '20:00',
    active: true,
  },
  {
    start: '20:00',
    end: '20:30',
    active: true,
  },
  {
    start: '20:30',
    end: '21:00',
    active: true,
  },
  {
    start: '21:00',
    end: '21:30',
    active: true,
  },
  {
    start: '21:30',
    end: '22:00',
    active: true,
  },
];

export function returnTimesByDay(weekday) {
  const specificWorkingDayTimes = ['09:00', '09:30', '19:00', '19:30'];
  if (weekday === 6) {
    return reservationTimes.filter(
      (time) => specificWorkingDayTimes.indexOf(time.start) === -1,
    );
  }
  return reservationTimes;
}

export const notificationTypes = [
  {
    label: 'Seminer',
    value: 'seminer',
    icon: 'https://ismekan-api.arneca.com/icons/seminer.png'
  },
  {
    label: 'Oda Giriş',
    value: 'odagiris',
    icon: 'https://ismekan-api.arneca.com/icons/odagiris.png'
  },
  {
    label: 'Süre Uzat',
    value: 'sureuzat',
    icon: 'https://ismekan-api.arneca.com/icons/sureuzat.png'
  },
  {
    label: 'Konum İzin',
    value: 'konumizin',
    icon: 'https://ismekan-api.arneca.com/icons/konumizin.png'
  },
  {
    label: 'Yakında',
    value: 'yakinda',
    icon: 'https://ismekan-api.arneca.com/icons/yakinda.png'
  },
  {
    label: 'Temizlik',
    value: 'temizlik',
    icon: 'https://ismekan-api.arneca.com/icons/temizlik.png'
  },
  {
    label: 'Pazarama',
    value: 'pazarama',
    icon: 'https://ismekan-api.arneca.com/icons/pazarama.png'
  },
];

export const longDateFormat = 'DD-MM-YYYY HH:mm';
export const shortDateFormat = 'DD-MM-YYYY';
