import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name } = props;
	let [event, setEvent] = useState([]);
	let _params = { query: {deleted: { $ne: true }}, sort: "-createdAt", page: 1, pageSize: 100000 };
	let languagesCms = props.languagesCms;
	useEffect(() => {
		api.get(`/rest/event?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result){
				let dbRooms = data.result.rows.sort((a, b) => a.name.localeCompare(b.name))
				setEvent(dbRooms);
			}
		})
	}, [])

	let findEventName = (eventId) => {
		const tmp = event.find((event) => event._id === eventId);
		return tmp ? tmp.name : null;
	}

	let selectedEvents = (v) => {
		console.log(v, "v")
		let selectedEvent = event.find(e => e._id === v)
		setRecord({ ...record, [name]: selectedEvent})
	}

	
	return <Select 
		defaultValue={findEventName(record[name]) ? record[name].name : record[name]}
		placeholder={"Etkinlik Seçiniz"}
		showSearch={true}
		onChange={selectedEvents}
		 filterOption={(input, option) =>
       		option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
			}>
				   
		{(event) && event.map(b =>
			<Select.Option key={b._id} value={b._id} >{b.name} {b.lastname}</Select.Option>)}
	</Select>;
};
