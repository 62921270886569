import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name } = props;
	let [attendee, setAttendee] = useState([]);
	let _params = { query: {deleted: { $ne: true }}, sort: "-createdAt", page: 1, pageSize: 100000 };
	let languagesCms = props.languagesCms;
	useEffect(() => {
		api.get(`/rest/user?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result){
				let dbAttendee = data.result.rows.sort((a, b) => a.name.localeCompare(b.name))
				setAttendee(dbAttendee);
			}
		})
	}, [])

	let findAttendeeName = (attendeeId) => {
		const tmp = attendee.find((attendee) => attendee._id === attendeeId);
		return tmp ? tmp.name : [];
	}

	let mentionedUsers = (v) => {
		console.log(v, "v")
		let mentionedUser = attendee.find(e => e._id === v)
		console.log(mentionedUser, "mentionedUser")
		setRecord({ ...record, [name]: v[0]})
	}

	// console.log(record, "record[name]")

	return <Select 
		defaultValue={findAttendeeName(record[name]) ? findAttendeeName(record[name]) : ''}
		placeholder={"Kullanıcı Seçiniz"}
		showSearch={true}
        mode="tags"
		onChange={mentionedUsers}
		 filterOption={(input, option) =>
       		option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
			}>
				   
		{(attendee) && attendee.map(b =>
			<Select.Option key={b._id} value={b._id} >{b.name} {b.lastname}</Select.Option>)}
	</Select>;
};
