"use strict";
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../service';
import moment from 'moment';
import { useCallback } from 'react';
import {
  LeftOutlined,
} from '@ant-design/icons';
import { MapInput } from '../components';
import {
  Button,
  Card,
  message,
  Form,
  Col,
  Input,
  Row} from 'antd';
import 'moment/locale/tr';
moment.locale('tr');

function EventLocationDetail() {
  let newRecord = {
    name: '',
    location: {},
    address: '',
    plan_address: '',
    plan: {},
    longitude: '',
    latitude: '',
    type: 'event',
  }
  const params = useParams();
  const history = useHistory();
  const id = params.id !== 'add' ? params.id : false;
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [validationCheck, setValidationCheck] = useState(false);
  const [loading, setLoading] = useState(id ? true : false);
  const [medias, setMedias] = useState([]);


  const save = (mydata) => {
    setValidationCheck(true);
    let err = validate()
    if(err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        data.address = data.location.address;
        data.plan_address = data.location.plan_address;
        data.plan = data.location.plan;
        data.longitude = data.location.longitude;
        data.latitude = data.location.latitude;
        data.type = 'event';
        api
          .put('/rest/location/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success('Başarıyla kaydedilmiştir', 2);
              history.push('/event-location');
            } else message.error(result_message.message, 2);
          });
      } else {
        console.log('data', data);
        data.address = data.location.address;
        data.plan_address = data.location.plan_address;
        data.plan = data.location.plan;
        data.longitude = data.location.longitude;
        data.latitude = data.location.latitude;
        data.type = 'event';

        api
          .post('/rest/location', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success('Başarıyla kaydedilmiştir', 2);
              history.push('/event-location');
            } else message.error(result_message.message, 2);
          });
      }
    }
  };


  useEffect(() => {
    if (id) {
      setLoading(true);
      api
        .get('/rest/location/' + id).then(({ data: { result, result_message } }) => {
          if (result) {
            setData(result);
            setLoading(false);
          }
            // setData(result);
            // setLoading(false);
          });
      }
    }, [id]);


  let validate = useCallback(() => {
    let errors = {};

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);


  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name: ""}</h1>
        </div>
        <div className="list-buttons">
          <div className="list-buttons py-5">
            <Link to="/event-location">
              <Button 
                type='danger'
                icon={<LeftOutlined />} 
                style={{
                  fontSize: '14px',
                  height: '100%',
                  marginRight: '10px',
                }}
                >
                  Geri
                </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={ id ? "Etkinlik Detayı Düzenle" : "Etkinlik Detayı Ekle"} loading={loading}>
          <Form layout="horizontal" size="large" onFinish={save}>
            <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label="Adı"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    placeholder="Adı"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label="Konum"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <MapInput  
                    record={data} 
                    setRecord={setData} 
                    name="location" 
                    disabled={false} 
                    title="coordinate" 
                    required={false} 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {"Kaydet"}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          
        </Card>
      </div>
    </div>
  );
}

export default EventLocationDetail;
