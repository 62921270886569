import React, { useEffect, useState } from 'react';
import api from '../service';
import qs from 'qs';
import Spinner from '../components/Loading';
import { Button, Popconfirm, Space, Table } from 'antd';
import { turkishSort } from '../stores/utils';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import auth from '../stores/auth';
import moment from 'moment';

function EventLocation() {
  const [search, setSearch] = useState(false);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const query = [];

  const get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, email, phone';
    }

    api
      .get(`/rest/location?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setLocations(
            data.result.rows.filter(location => location.type === 'event').map((item, key) => ({
              ...item,
              key: key,
            })),
          );
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, rows) => {
      setSelectedRows(rows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  const deleteRow = async (item_id) => {
    api.delete(`/rest/event/${item_id}`);
    get();
  };

  const deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/event/`);
      let newData = locations.filter((el) => el._id !== item._id);
      setLocations(newData);
      get();
    });
  };

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Adres',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Tarih',
      key: 'createdAt',
      render: (row) => moment(row.createdAt).format('DD-MM-YYYY')
    },
    {
      title: 'İşlemler',
      key: 'action',
      render: (row) => (
        <div className="tbl-actions">
          <Space size="small">
            <Link to={'/event-location/edit/' + row._id}>
              <Button icon={<EditOutlined />}>Düzenle</Button>
            </Link>
            <Popconfirm
              onConfirm={() => deleteRow(row._id)}
              title={'Silme işlemini onaylıyor musunuz?'}
              okText={'Evet'}
              cancelText={'İptal'}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                Sil
              </Button>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  return (
    <div>
      {loading && <Spinner />}
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons py-5">
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            size="large"
            onClick={deleteSelectedRows}
            style={{
              fontSize: '14px',
              height: '100%',
              marginRight: '10px',
            }}
          >
            Seçilenleri Sil
          </Button>

          <Link to="/event-location/add" style={{ marginRight: '5px' }}>
            <Button
              type="light"
              style={{ fontSize: '14px', height: '100%' }}
              icon={<PlusOutlined />}
              size="large"
            >
              Etkinlik Lokasyonu Ekle
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <div className="table-wrapper">
          <Table
            dataSource={locations}
            columns={columns}
            onChange={handleTableChange}
            pagination={{
              total: totalCount,
            }}
            rowSelection={{ ...rowSelection }}
          />
        </div>
      </div>
    </div>
  );
}

export default EventLocation;
