import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  Alert,
  Breadcrumb,
  DatePicker,
  Switch,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { CityPicker, FileInputSingle } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/tr_TR';

const { Option } = Select;

const UserDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;

  const [authorizations] = useGlobal('authorizations');
  const [user] = useGlobal('user');

  let newRecord = {
    name: '',
    phone: '',
    email: '',
  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');

  //onetime run
  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get('/rest/user/' + id).then(({ data: { result, result_message } }) => {
          delete result.password;
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        // if (data.phone && !data.phone.startsWith('+')) {
        //   data.phone = '+' + data.phone;
        // }
        api
          .put('/rest/user/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Düzenle", 2);
              history.push('/user');
            } else message.error(result_message.message, 2);
          });
      } else {
        // if (data.phone && !data.phone.startsWith('+')) {
        //   data.phone = '+' + data.phone;
        // }
        api
          .post('/rest/user', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Kaydet", 2);
              history.push('/user');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  console.log(data, 'data')

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h3 style={{ color: '#fff', marginBottom: '8px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a
                  style={{ color: '#fff', fontSize: '18px' }}
                  href="/user"
                >
                  Kullanıcılar
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </h3>
        </div>

        <div className="list-buttons">
          <Link to="/user">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100%' }}
            >
              {!isSmall && "Geri"}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? "Düzenle" : "Ekle"}
          loading={loading}
        >
          <Form
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
             <Row direction="row">
              
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Ad Soyad"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                 <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Email"
                  required
                  help={errors.email}
                  validateStatus={errors.email ? 'error' : 'success'}
                >
                 <Input
                    name="email"
                    value={data.email}
                    onChange={(e) => setData({ ...data, email: e.target.value })}
                  />
                </Form.Item>
              </Col>

            </Row>

            <Row direction="row">

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Telefon"
                  required
                  help={errors.phone}
                  validateStatus={errors.phone ? 'error' : 'success'}
                >
                 <Input
                    name="phone"
                    value={data.phone}
                    onChange={(e) => setData({ ...data, phone: e.target.value })}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Yeni Şifre"
                  help={errors.password}
                  validateStatus={errors.password ? 'error' : 'success'}
                >
                 <Input
                    name="password"
                    // type="password"
                    value={data.password}
                    onChange={(e) => setData({ ...data, password: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Aktivasyon Kodu"
                  help={errors.code}
                  validateStatus={errors.code ? 'error' : 'success'}
                >
                 <Input
                    name="code"
                    value={data.code}
                    onChange={(e) => setData({ ...data, code: e.target.value })}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Aktif"
                  required
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch checked={data.active ? true : false} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={v => setData({ ...data, active: v })} />             
                </Form.Item>
              </Col>

            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Avatar"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={"Yükle"}
                    record={data}
                    setRecord={setData}
                    name="avatar"
                  />
                  <Alert
                    message="Avatar 1024 x 1024 çözünürlüğünde olmalıdır."
                    banner
                  />
                </Form.Item>
              </Col>
              
            </Row>
           
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {"Kaydet"}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default UserDetail;
