import React, { useEffect } from 'react';
import { setGlobal, useGlobal } from 'reactn';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Login from './pages/Login';
import Admin from './Admin';
import axios from 'axios';
import api from './service';
import Cookies from 'js-cookie';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { setAuth } from './stores/auth'
import { setAlert } from './stores/alert'

axios.interceptors.response.use(
  (result) => {
    return result;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      setGlobal({ token: null });
    }
    return Promise.reject(err);
  },
);

setGlobal({
  isSmall: window.innerWidth <= 1024,
  collapsed: false,
  token: Cookies.get('token'),

  user:
    localStorage.getItem('user') === undefined
      ? {}
      : JSON.parse(localStorage.getItem('user'))

  // logo: localStorage.getItem("logo") === undefined ? "" : JSON.parse(localStorage.getItem("logo")),
  // pdfIcon: localStorage.getItem("pdfIcon") === undefined ? "" : JSON.parse(localStorage.getItem("pdfIcon")),
  // login_setting: localStorage.getItem("login_setting") === undefined ? "" : JSON.parse(localStorage.getItem("login_setting")),
});

const App = (props) => {
  let [, setToken] = useGlobal('token');
  let [isSmall, setIsSmall] = useGlobal('isSmall');
  let [, setCollapsed] = useGlobal('collapsed');
  let [, setLangsCms] = useGlobal('langCms');
  let [langCode, setLangCode] = useGlobal('langCode');
  const dispatch = useDispatch()

  // useEffect(() => {
  //   api.get(`/rest/authorizations`).then( async ({ data }) => {
  //     if ( data && data.result) {
  //       await dispatch(setAuth(data.result))
  //     } 
  //   })
  //   api.get(`/rest/alert-count`).then( async ({ data }) => {
  //     if ( data && data.result) {
  //       await dispatch(setAlert(data.result))
  //     } 
  //   })
  // }, []);

  useEffect(() => {
    window.onresize = () => {
      setIsSmall(window.innerWidth < 1024);
    };
  }, [setIsSmall, setToken]);
  useEffect(() => {
    if (isSmall) setCollapsed(true);
    else setCollapsed(false);
  }, [isSmall, setCollapsed]);

  return (
    <Router>
      <Switch>
        <Route key="login" path="/login" component={Login} />
        <Route key="pages" path="/" component={Admin} />
      </Switch>
    </Router>
  );
};

export default App;
