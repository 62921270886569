import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm, message } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import Spinner from '../components/Loading';
import { turkishSort } from '../stores/utils';

const Room = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [excelData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(true);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let [room] = useGlobal('room');
  const { auth } = useSelector((state) => state.auth);

  let query = [];

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, email, phone';
    }

    let restData = await api
      .get(`/rest/room?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setTotalCount(data.result.total);
          setData(
            data.result.rows.map((item, key) => {
              if (item.active) {
                item.active = <div className="true-circle"></div>;
              } else {
                item.active = <div className="false-circle"></div>;
              }
              item.key = key;
              return item;
            }),
          );
        }
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/room/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/room/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: 'Oda Adı',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'İşlemler',
      key: 'action',
      align: 'center',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          {!(
            auth &&
            auth
              .find((a) => a.number === room.role)
              .restraintList.includes('edit')
          ) && (
            <Link to={'/room/edit/' + record._id}>
              <Button icon={<EditOutlined />}>{!isSmall && 'Düzenle'}</Button>
            </Link>
          )}
          {!(
            auth &&
            auth
              .find((a) => a.number === room.role)
              .restraintList.includes('delete')
          ) && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={'Sil'}
              okText={'Sil'}
              cancelText={'İptal'}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                {!isSmall && 'Sil'}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading && <Spinner />}
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons py-5">
          {!(
            auth &&
            auth
              .find((a) => a.number === room.role)
              .restraintList.includes('delete')
          ) &&
            !isSmall && (
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                size="large"
                onClick={deleteSelectedRows}
                style={{
                  fontSize: '14px',
                  height: '100%',
                  marginRight: '10px',
                }}
              >
                Seçilenleri Sil
              </Button>
            )}

          {!(
            auth &&
            auth
              .find((a) => a.number === room.role)
              .restraintList.includes('add')
          ) && (
            <Link to="/room/add" style={{ marginRight: '5px' }}>
              <Button
                type="light"
                style={{ fontSize: '14px', height: '100%' }}
                icon={<PlusOutlined />}
                size="large"
              >
                {!isSmall && 'Oda Ekle'}
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Arama..."
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default Room;
