import { DeleteOutlined,EditOutlined,PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Popconfirm, Space, Table } from 'antd';
import qs from 'qs';
import moment from 'moment';
import 'moment/locale/tr';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../components/Loading';
import api from '../service';
import { turkishSort } from '../stores/utils';
moment.locale('tr');

function StaticNotification() {
  const [search, setSearch] = useState(false);
  const [eventLocations, setEventLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [attendee, setAttendee] = useState([]);

  const query = [];

  const get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 1000 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name';
    }

    api
      .get(`/rest/staticNotification?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          console.log(data.result.rows)
          setEventLocations(
            data.result.rows.map((item, key) => ({
              ...item,
              key: key,
            })),
          );
        }
      })
      .then(() => {
        setLoading(false);
      });

    api
      .get(`/rest/user?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setAttendee(
            data.result.rows.map((item, key) => ({
              ...item,
              key: key,
            })),
          );
        }
      })
  };

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, rows) => {
      setSelectedRows(rows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  const deleteRow = async (item_id) => {
    api.delete(`/rest/staticNotification/${item_id}`);
    setLoading(true);
    get();
  };

  const deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/staticNotification/`);
      let newData = eventLocations.filter((el) => el._id !== item._id);
      setEventLocations(newData);
      get();
    });
  };

  let columns = [
    {
      title: 'Sabit Bildirim İçeriği',
      dataIndex: 'name',
      key: 'name',
      sorter: (a,b) => turkishSort(a.name,b.name),
      sortdirection: ['ascend', 'descend'],
    },
    {
      title: "Kullanıcı",
      dataIndex: 'userId',
      key: 'userId',
      render: (text, record) => {
        return (
          <div>
            {attendee.map((item) => {
              if (item._id === record.userId) {
                return item.name ? item.name : "-"
              }
            })}
          </div>
        )
      }
    },
    {
      title: 'Durum',
      dataIndex: 'active',
      key: 'active',
      render: (text, record) => {
        return (
          <Badge
            className='site-badge-count-109'
            count={record.active ? 'Aktif' : 'Pasif'}
            style={{
              backgroundColor: record.active ? '#52c41a' : '#f5222d',
            }}
          />
        )
      }
    },
    {
      title: 'Bildirim Türü',
      dataIndex: 'notificationType',
      key: 'notificationType',
      render: (text, record) => {
        return (
          <Badge
            className='site-badge-count-109'
            count={"Sabit Bildirim"}
            style={{
              backgroundColor: '#1890ff',
            }}
          />
        )
      }
    },
    {
      title:"İşlemler",
      key: 'action',
      render: (row) => (
        <div className="table-actions">
          <Space size="small">
            <Link to={'/static-notification/edit/' + row._id}>
              <Button
                icon={<EditOutlined />}>Düzenle</Button>
            </Link>
            <Popconfirm
              title="Bu kaydı silmek istediğinize emin misiniz?"
              onConfirm={() => deleteRow(row._id)}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button
                type="danger"
                icon={<DeleteOutlined />}>Sil</Button>
            </Popconfirm>
          </Space>
        </div>
      )
    }
  ];

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  return (
    <div>
      {loading && <Spinner />}
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons py-5">
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            size="middle"
            onClick={deleteSelectedRows}
            style={{
              fontSize: '1rem',
              height: '100%',
              marginRight: '10px',
            }}
          >
            Seçilenleri Sil
          </Button>

					

            <Button
              href='/static-notification/add'
              type="text"
              icon={<PlusOutlined />}
              size="middle"
              style={{
                fontSize: '1rem',
                height: '100%',
                marginRight: '10px',
                backgroundColor: '#1486a6',
                color: 'white'
              }}
            >
              Yeni Ekle
            </Button>
        </div>
      </div>

      <div className="table-wrap">
        <div className="table-wrapper">
          <Table
            dataSource={eventLocations}
            columns={columns}
            onChange={handleTableChange}
            pagination={{
              total: totalCount,
              pageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
            rowSelection={rowSelection}
          />
        </div>
      </div>
    </div>
  );
}

export default StaticNotification;
