import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  Alert,
  Breadcrumb,
  DatePicker,
  Switch,
  TimePicker,
  InputNumber,
  Badge
} from 'antd';
import { useHistory } from 'react-router-dom';
import Icon,{ LeftOutlined,ClockCircleOutlined,CustomIconComponentProps } from '@ant-design/icons';
import { CityPicker, FileInputSingle,MapInput,TagsPicker,RoomFeature ,RoomTypePicker,FileInput,WorkDayPicker,RoomLocationPicker } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import 'react-phone-input-2/lib/style.css';
import { returnTimesByDay } from '../stores/utils';
import moment from 'moment';

const { Option } = Select;

const RoomDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;

  const [authorizations] = useGlobal('authorizations');
  const [room] = useGlobal('room');
  let [langCms] = useGlobal("langCms"); 
  let [langCode] = useGlobal("langCode");

  // const clockIconSVG = () => (
  //   <svg t="1675534463709" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3092" width="20" height="20"><path d="M512 149.333333c200.298667 0 362.666667 162.368 362.666667 362.666667s-162.368 362.666667-362.666667 362.666667S149.333333 712.298667 149.333333 512 311.701333 149.333333 512 149.333333z m0 64c-164.949333 0-298.666667 133.717333-298.666667 298.666667s133.717333 298.666667 298.666667 298.666667 298.666667-133.717333 298.666667-298.666667-133.717333-298.666667-298.666667-298.666667z m32 78.485334v201.130666h140.757333v64H512a32 32 0 0 1-32-32V291.84h64z" fill="#007FFC" p-id="3093"></path></svg>
  // )
  // const ClockIcon = (props) => <Icon component={clockIconSVG} {...props} />;


  let newRecord = {
    // lang: langCode ? langCode : langCms? langCms[0].code : "",
    name: '',
    total_limit: '',
    price: '',
    is_favorite : false,
    medias : [],
    location : [],
    date: '',
    location_tag: 'Nişantaşı',
    content : '',
    currency : 'TL',
    status : {},
    tag:'Yaşam',
    tags: [],
    time: 30,
    working_days : [ 
        {
            "name" : "Pazartesi - Cumartesi",
            "time" : "09:00 - 19:30"
        }, 
        {
            "name" : "Pazar",
            "time" : "10:00 - 19:30"
        }
    ],
    roomtype: '',
    avatar: [],

  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');

  //onetime run
  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get('/rest/room/' + id).then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        if (data.mspName) data.mspName = data.mspName.toString();
        if (data.phone && !data.phone.startsWith('+')) {
          data.phone = '+' + data.phone;
        }
        api
          .put('/rest/room/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Düzenle", 2);
              history.push('/room');
            } else message.error(result_message.message, 2);
          });
      } else {
        if (data.mspName) data.mspName = data.mspName.toString();
        if (data.phone && !data.phone.startsWith('+')) {
          data.phone = '+' + data.phone;
        }
        if (data.stationId === '') data.stationId = [];
        api
          .post('/rest/room', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Kaydet", 2);
              history.push('/room');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  console.log("data", data)

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h3 style={{ color: '#fff', marginBottom: '8px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a
                  style={{ color: '#fff', fontSize: '18px' }}
                  href="/room"
                >
                  Toplantı Odaları
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </h3>
        </div>

        <div className="list-buttons">
          <Link to="/room">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100%' }}
            >
              {!isSmall && "Geri"}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? "Düzenle" : "Ekle"}
          loading={loading}
        >
          <Form
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === room.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
             <Row direction="row">
              
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Oda Adı"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                 <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    placeholder="Oda adı"
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kota"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <InputNumber
                    name="total_limit"
                    value={data.total_limit}
                    onChange={(e) => setData({ ...data, total_limit: e })}
                    placeholder="Kişi sayısı"
                  />
                </Form.Item>
              </Col>

            </Row>

            <Row direction="row">

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Ücret"
                  help={errors.name}
                  required
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <InputNumber
                    name="price"
                    value={data.price}
                    onChange={(e) => setData({ ...data, price: e })}
                    placeholder="Ücret (₺)"
                    type="number"
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Durum"
                  required
                  help={errors.password}
                  validateStatus={errors.password ? 'error' : 'success'}
                >
                  <RoomFeature
                    setRecord={setData}
                    record={data}
                    name="status"
                    value={data.status}
                    onChange={(e) => setData({ ...data, status: e })}
                  />

                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Tags"
                  help={errors.code}
                  required
                  validateStatus={errors.code ? 'error' : 'success'}
                >
                 <TagsPicker
                    setRecord={setData}
                    record={data}
                    name="tags"
                    value={data.tags}
                    onChange={(e) => setData({ ...data, tags: e })}
                    placeholder="Tags Seçiniz"
                  />

                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Tarih"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <DatePicker
                    name="date"
                    defaultValue={()=>moment(data.date,"DD-MM-YYYY HH:mm")}
                    value={data.date ? moment(data.date,"DD-MM-YYYY HH:mm") : null}
                    onChange={(e) => setData({ ...data, date: e })}
                    format="DD-MM-YYYY HH:mm"
                    showTime={{ format: 'HH:mm' }}
                    placeholder="Tarih Seçiniz"
                  />
                  {/* <TimePicker.RangePicker
                    name="date"
                    value={data.date}
                    onChange={(e) => setData({ ...data, date: e })}
                    format="HH:mm"
                    showTime={{ format: 'HH:mm' }}
                    placeholder={['Başlangıç', 'Bitiş']}
                  /> */}
                </Form.Item>
              </Col>

              {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Aktif"
                  required
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch 
                    name="status" 
                    checked={data.active ? true : false} 
                    checkedChildren="Aktif" 
                    unCheckedChildren="Pasif" 
                    onChange={v => setData({ ...data, active: v })} 
                  />             
                </Form.Item>
              </Col> */}

            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Oda Tipi"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <RoomTypePicker
                    setRecord={setData}
                    record={data}
                    name="roomtype"
                    value={data.roomtype}
                    onChange={(e) => setData({ ...data, roomtype: e })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Zaman( dk )"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Icon type="clock-circle" />
                  <InputNumber
                    readOnly
                    style={{ width: '100%' }}
                    name="time"
                    value={data.time}
                    defaultValue={"30"}
                    onChange={(e) => setData({ ...data, time: e })}
                    placeholder="Oda süresi (dk)"
                  />
                  <Alert banner message="Oda süresi varsayılan olarak 30 dk olarak ayarlanmıştır." />
                </Form.Item>
              </Col>
            </Row>

            

            {/* <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kota"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <InputNumber
                    name="quota"
                    value={data.quota}
                    onChange={(e) => setData({ ...data, quota: e })}
                    placeholder="Kişi sayısı"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Ücret"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <InputNumber
                    name="price"
                    value={data.price}
                    onChange={(e) => setData({ ...data, price: e })}
                    placeholder="Ücret (₺)"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row> */}

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label="Konum"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <RoomLocationPicker
                    setRecord={setData}
                    record={data}
                    name="location"
                    // value={data.location}
                    onChange={(e) => setData({ ...data, location: e })}
                  />
                  {/* <MapInput  
                    record={data} 
                    setRecord={setData} 
                    name="location" 
                    disabled={false} 
                    title="coordinate" 
                    required={false} 
                  /> */}


                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Oda Özellikleri"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <RoomFeature
                    record={data}
                    setRecord={setData}
                    name="tags"
                  />
                </Form.Item>
              </Col> */}
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label="Oda Açıklaması"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input.TextArea
                    name="content"
                    value={data.content}
                    onChange={(e) => setData({ ...data, content: e.target.value })}
                    placeholder="Oluşturacağınız oda hakkında kısa bir açıklama yazınız."
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Avatar"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >

                  <FileInputSingle
                    languagesCms={"Yükle"}
                    record={data}
                    setRecord={setData}
                    name="avatar"
                    pop = {false}
                  />
                  <Alert
                    message="Avatar 1024 x 1024 çözünürlüğünde olmalıdır."
                    banner
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Oda Resimleri"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInput 
                    name='medias' 
                    title='Medyalar' 
                    type='file' 
                    multi={true} 
                    ext={['image', 'video']} 
                    record={data} 
                    setRecord={setData} 
                  />

                  <Alert
                    message="Oda resimleri 1024 x 1024 çözünürlüğünde olmalıdır."
                    banner
                  />
                </Form.Item>
              </Col>
            </Row>
           
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {"Kaydet"}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default RoomDetail;
