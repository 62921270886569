import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name } = props;
	let [room, setRoom] = useState([]);
	let [location, setLocation] = useState([]);
	let _params = { query: {deleted: { $ne: true }}, sort: "-createdAt", page: 1, pageSize: 100000 };
	let languagesCms = props.languagesCms;
	useEffect(() => {
		api.get(`/rest/location?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result){
				let dbRooms = data.result.rows.sort((a, b) => a.name.localeCompare(b.name))
				dbRooms.map((r) => {
					if(r.type === 'event') {
						setLocation([...location, r])
					}
				})
				
			}
		})
	}, [])




	let selectedRoomLocation = (v) => {
		console.log(v, "v")
		let selectedRoomLocation = location.find(e => e.name === v)
		console.log(selectedRoomLocation, "selectedRoomLocation")
		setRecord({ ...record, [name]: selectedRoomLocation})
	}
	console.log(location, "location")
	
	return <Select 

		defaultValue={record[name] ? record[name].name : record[name]}
		placeholder={"Etkinlik Lokasyonu Seçiniz"}
		showSearch={true}
		onChange={selectedRoomLocation}
		 filterOption={(input, option) =>
       		option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
			}>
				   
		{(location) && location.map(b =>
			<Select.Option key={b._id} value={b.name} >{b.name}</Select.Option>)}
	</Select>;
};
