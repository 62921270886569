import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../service';
import moment from 'moment';
import {
  LeftOutlined,
} from '@ant-design/icons';
import { MapInput,FileInputSingleArray,EventTypePicker,FileInput,EventLocationPicker } from '../components';
import {
  Button,
  Card,
  DatePicker,
  message,
  Switch,
  Form,
  Col,
  Row,
  Input,
  Select,
  Alert} from 'antd';
import { useCallback } from 'react';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
moment.locale('tr');

function EventDetail() {

  let newRecord = {
    content: '',
    status: false,
    startTime: '',
    is_favorite: false,
    medias: [],
    price: 0,
    location: {},
    date: '',
    location_tag: 'Nişantaşı',
    tag: '',
    endTime: '',
    available_count: 0,
    currency: 'TL',
    name: '',
    total_limit: 0,
  }


  const params = useParams();
  const history = useHistory();
  const id = params.id !== 'add' ? params.id : false;
  let [data, setData] = useState(id ? [] : newRecord);
  const [loading, setLoading] = useState(id ? true : false);
  let [errors, setErrors] = useState([]);
  let [validationCheck, setValidationCheck] = useState(false);
  const [startDate, setStartDate] = useState();
  const [locations, setLocations] = useState([]);



  const save = (mydata) => {

    setValidationCheck(true);
    let err = validate()
    if(err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        data.date = moment(startDate).format('DD-mm-yyyy');
        data.startTime = moment(data.startTime).format('DD-mm-yyyy HH:mm');
        data.endTime = moment(data.endTime).format('DD-mm-yyyy HH:mm');
        api
          .put('/rest/event/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success('Başarıyla kaydedilmiştir', 2);
              history.push('/event');
            } else message.error(result_message.message, 2);
          });
      } else {
        console.log('data', data);
        data.date = moment(startDate).format('DD-mm-yyyy');
        data.startTime = moment(data.startTime).format('DD-mm-yyyy HH:mm');
        data.endTime = moment(data.endTime).format('DD-mm-yyyy HH:mm');
        
        api
          .post('/rest/event', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success('Başarıyla kaydedilmiştir', 2);
              history.push('/event');
            } else message.error(result_message.message, 2);
          });
      }
    }
  };


  useEffect(() => {

    const params = { page: 1, pageSize: 10 };
    api.get(`/rest/location`, { params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setLocations(
            data.result.rows.filter(location => location.type === 'event').map((item, key) => ({
              key: item._id,
              value: item._id,
              label: item.name,
            })),
          );
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      api
        .get('/rest/event/' + id).then(({ data: { result, result_message } }) => {
          if (result) {
            setData(result);
            setLoading(false);
          }
            // setData(result);
            // setLoading(false);
          });
      }
    }, [id]);


    let validate = useCallback(() => {
      let errors = {};
  
      errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
      errors.hasError = errors.all.length > 0;
      return errors;
    }, [data]);
  
    useEffect(() => {
      if (validationCheck) setErrors(validate());
    }, [validationCheck, data, validate]);



    
    // console.log('data', data.tag);

    return (
      <div>
        <div className="list-head">
          <div className="list-title">
            <h1>{module ? module.name: ""}</h1>
          </div>
          <div className="list-buttons">
            <div className="list-buttons py-5">
              <Link to="/event">
                <Button 
                  type='danger'
                  icon={<LeftOutlined />} 
                  style={{
                    fontSize: '14px',
                    height: '100%',
                    marginRight: '10px',
                  }}
                  >
                    Geri
                  </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="form-wrap">
          <Card title={ id ? "Etkinlik Düzenle" : "Etkinlik Ekle"} loading={loading}>
            <Form layout="horizontal" size="large" onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Etkinlik Adı"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <Input
                      name="name"
                      value={data.name}
                      onChange={(e) => setData({ ...data, name: e.target.value })}
                      placeholder="Etkinlik Adı"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Durum"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <Switch
                      name="status"
                      checked={data.status}
                      checkedChildren="Aktif"
                      unCheckedChildren="Pasif"
                      onChange={(e) => setData({ ...data, status: e })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Ücret"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <Input
                      name="price"
                      value={data.price}
                      onChange={(e) => setData({ ...data, price: Number(e.target.value) })}
                      placeholder="Ücret"
                      type="number"
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Para Birimi"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <Select
                      name="currency"
                      value={data.currency}
                      onChange={(e) => setData({ ...data, currency: e })}
                      placeholder="Para Birimi"
                      >
                      <Select.Option value="TL">TL</Select.Option>
                      {/* <Option value="USD">USD</Option>
                      <Option value="EUR">EUR</Option> */}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Kontenjan"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <Input
                      name="total_limit"
                      value={data.total_limit}
                      onChange={(e) => setData({ ...data, total_limit: Number(e.target.value) })}
                      placeholder="Kontenjan"
                      type="number"
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                    label="Boş Kontenjan"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <Input
                      name="available_count"
                      value={data.available_count}
                      onChange={(e) => setData({ ...data, available_count: Number(e.target.value) })}
                      placeholder="Boş Kontenjan"
                      type="number"
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Başlangıç Tarihi"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <DatePicker
                      name="startTime"
                      defaultValue={()=>moment(data.startTime,"DD-MM-YYYY HH:mm")}
                      value={data.startTime ? moment(data.startTime,"DD-MM-YYYY HH:mm") : null}
                      onChange={(e) => setData({ ...data, startTime: e })}
                      placeholder="Başlangıç Tarihi"
                      format="DD-MM-YYYY HH:mm"
                      showTime={{ format: 'HH:mm' }}
                      locale={locale}
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Bitiş Tarihi"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <DatePicker
                      name="endTime"
                      defaultValue={()=>moment(data.endTime,"DD-MM-YYYY HH:mm")}
                      value={data.endTime ? moment(data.endTime,"DD-MM-YYYY HH:mm") : null}
                      onChange={(e) => setData({ ...data, endTime: e })}
                      placeholder="Bitiş Tarihi"
                      format="DD-MM-YYYY HH:mm"
                      showTime={{ format: 'HH:mm' }}
                      locale={locale}
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Etkinlik Çeşidi"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <EventTypePicker
                      value={data.tag ? data.tag && [data.tag]  : []}
                      onChange={(e) => setData({ ...data, tag: e })}
                      record={data}
                      setRecord={setData}
                      name="tag"
                    />
                    {/* <Select
                      value={data.tag ? data.tag : []}
                      mode="tag"
                      style={{ width: '100%' }}
                      placeholder="Tag"
                      onChange={(e) => setData({ ...data, tag: e })}
                      options={options}
                    >
                      <Select.Option value="finansal">Finansal</Select.Option>
                      <Select.Option value="teknoloji">Teknoloji</Select.Option>
                      <Select.Option value="saglik">Sağlık</Select.Option>
                      <Select.Option value="cocuklar">Çocuklar</Select.Option>
                      <Select.Option value="yasam">Yaşam</Select.Option>
                    </Select> */}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="Konum"
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <EventLocationPicker
                      setRecord={setData}
                      record={data}
                      name="location"
                      value={data}
                      onChange={(e) => setData({ ...data, location: e })}
                    />
                    {/* <MapInput  
                      record={data} 
                      setRecord={setData} 
                      name="location" 
                      disabled={false} 
                      title="coordinate" 
                      required={false} 
                    /> */}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="Açıklama"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <Input.TextArea
                      name="content"
                      value={data.content ? data.content : ''}
                      onChange={(e) => setData({ ...data, content: e.target.value })}
                      placeholder="Açıklama"
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="Medya"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <FileInput
                      name='medias' 
                      title='Medyalar' 
                      type='file' 
                      multi={true} 
                      ext={['image']} 
                      record={data} 
                      setRecord={setData} 
                    />

                    {/* <FileInput
                      name='medias'
                      title='Medyalar'
                      type='file'
                      multi={true}
                      record={data}
                      setRecord={setData}
                    /> */}
                    {/* <FileInputSingleArray
                      name='medias' 
                      title='Medyalar' 
                      type='file' 
                      multi={false} 
                      record={data} 
                      setRecord={setData} 
                    /> */}
                    <Alert message="Yüklenecek görüntü 1200 x 834 çözünürlüğünde olmalıdır." type="info" />

                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button
                      style={{
                        backgroundColor: '#9eb23b',
                        borderColor: 'transparent',
                      }}
                      type="primary"
                      disabled={loading}
                      htmlType="submit"
                      size="large"
                      block
                    >
                      {' '}
                      {"Kaydet"}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            
          </Card>
        </div>
      </div>
    );
}

export default EventDetail;
