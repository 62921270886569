import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../service';
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  InputNumber,
  Alert,
  Button,
  DatePicker,
  message,
  Select,
  Card,
  Switch,
  Upload,
  Form,
} from 'antd';
import {
  DeleteOutlined,
  LeftOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { DbSelectAttendee,FileInputSingleArray } from '../components';
import moment from 'moment';
import { useCallback } from 'react';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
moment.locale('tr');

function NotificationDetail() {

  const newRecord = {
    name:              '',
    active:             true,
    completed:          false,
    attendees:          [],
    deeplink:           '',
    media:              '',
    action:             {},
    date:               new Date(),
    icon:               "https://ismekan-api.arneca.com/icons/notification.png",
    type:               'pushnotification',
    bgType:             'konumizin',
  }
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [validationCheck, setValidationCheck] = useState(false);
  let [loading, setLoading] = useState(id ? true : false);


  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get('/rest/notification/' + id).then(({ data: { result, result_message } }) => {
        console.log(result,"result")
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);



  const save = (mydata) => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        if(data.button) {
          data.action = {
            name: data.buttonName,
            url: data.buttonUrl,
          }
        }
        api
          .put('/rest/pushNotification/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Başarılı bir şekilde düzenlendi", 2);
              history.push('/notification');
            } else message.error(result_message.message, 2);
          });
      } else {

        console.log(data,"data,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,")
        if(data.button) {
          data.action = {
            name: data.buttonName,
            url: data.buttonUrl,
          }
        }
        api
          .post('/rest/pushNotification', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Kaydedildi", 2);
              history.push('/notification');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  console.log(data,"data")


  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h3 style={{ color: '#fff', marginBottom: '8px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a
                  style={{ color: '#fff', fontSize: '18px' }}
                  href="/notification"
                >
                  Bildirimler
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </h3>
        </div>

        <div className="list-buttons">
          <Link to="/notification">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100%' }}
            >
              Geri
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? "Düzenle" : "Ekle"}
          loading={loading}
        >
          <Form
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Bildirim Adı / Açıklama"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                 <Input.TextArea
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    placeholder="Bildirim adı / açıklama"
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="DeepLink"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="deeplink"
                    value={data.deeplink ? data.deeplink : ''}
                    onChange={(e) => setData({ ...data, deeplink: e.target.value })}
                    placeholder="Deep link"
                    type='text'
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kullanıcılar"
                  help={errors.name}
                  required
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <DbSelectAttendee 
                    url='user' 
                    placeholder="Kullanıcı" 
                    record={data} 
                    setRecord={setData} 
                    name="attendees" 
                    mode="multiple"
                  />

                    <Alert
                      message="Bildirimde gösterilecek kullanıcıları seçiniz.
                      Birden fazla kullanıcı seçebilirsiniz."
                      type="info"
                      showIcon
                    />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Bildirim Gönderim Tarihi"
                  help={errors.name}
                  required
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <DatePicker
                    name="date"
                    defaultValue={()=>moment(data.date, 'DD-MM-YYYY HH:mm')}
                    value={data.date ? moment(data.date, 'DD-MM-YYYY HH:mm') : null}
                    onChange={(e) => setData({ ...data, date: e })}
                    placeholder="Tarih"
                    format={'DD-MM-YYYY HH:mm'}
                    showTime={{ format: 'HH:mm' }}
                    locale={locale}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Medya"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInputSingleArray
                    record={data}
                    setRecord={setData}
                    name="media" 
                    placeholder="Medya"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Buton Oluşturmak İstiyor musunuz?"
                  help={errors.name}
                  required
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Switch
                    checkedChildren="Evet"
                    unCheckedChildren="Hayır"
                    checked={data.button}
                    onChange={(e) => setData({ ...data, button: e })}
                  />
                </Form.Item>
              </Col>
            </Row>

            {data.button && (
              <>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                    label="Buton Adı"
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                    >
                      <Input
                        name="buttonName"
                        value={data.buttonName}
                        onChange={(e) => setData({ ...data, buttonName: e.target.value })}
                        placeholder="Buton adı"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="Buton Url"
                      help={errors.name}
                      validateStatus={errors.name ? 'error' : 'success'}
                    >
                      <Input
                        name="buttonUrl"
                        value={data.buttonUrl}
                        onChange={(e) => setData({ ...data, buttonUrl: e.target.value })}
                        placeholder="Buton url"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

          
            

            



           
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {"Kaydet"}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default NotificationDetail;
