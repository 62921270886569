import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name } = props;
	let [room, setRoom] = useState([]);
	let _params = { query: {deleted: { $ne: true }}, sort: "-createdAt", page: 1, pageSize: 100000 };
	let languagesCms = props.languagesCms;
	useEffect(() => {
		api.get(`/rest/room?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result){
				let dbRooms = data.result.rows.sort((a, b) => a.name.localeCompare(b.name))
				setRoom(dbRooms);
			}
		})
	}, [])

	let findRoomName = (roomId) => {
		const tmp = room.find((room) => room._id === roomId);
		return tmp ? tmp.name : null;
	}

	let selectedRooms = (v) => {
		console.log(v, "v")
		let selectedRoom = room.find(e => e._id === v)
		setRecord({ ...record, [name]: selectedRoom})
	}

	console.log(record, "test")

	
	return <Select 
		defaultValue={record[name] ? record[name].name : record[name]}
		placeholder={"Oda Seçiniz"}
		showSearch={true}
		onChange={selectedRooms}
		 filterOption={(input, option) =>
       		option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
			}>
				   
		{(room) && room.map(b =>
			<Select.Option key={b._id} value={b._id} >{b.name} {b.lastname}</Select.Option>)}
	</Select>;
};
