import React, { useState, useMemo } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name, mode, url,placeholder } = props;
  let [cities, setCities] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 10000 };
  let languagesCms = props.languagesCms;

  useMemo(() => {
    api.get(`/rest/${url}?${qs.stringify(_params)}`).then(({ data }) => {
      if (data && data.result && data.result.rows.length > 0) {
        let dbCities = data.result.rows.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        dbCities = dbCities.filter(x => x.deleted != true)
        setCities(dbCities);
      }
    });
  }, []);

  let setRecordArr = (v) => {
    console.log("v",v);
    // let result = cities.filter(x => x._id === v[0]);
    // console.log("result",result);

    setRecord({...record, [name]: v})
  }



  console.log("record[name]",record[name])

    return (
      <Select
        // value={record[name].map((x) => x._id) || []}
        showSearch={true}
        mode={mode}
        placeholder={placeholder}
        onChange={(v) => setRecordArr(v)}
        filterOption={(input, option) =>
          option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {cities &&
          cities.map((b) => (
            <Select.Option key={b.name} value={b._id}>
             {b.name} {b.lastname}
            </Select.Option>
          ))}
      </Select>
    );
};
