import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm, message } from 'antd';
import moment from 'moment';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const User = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [excelData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let [user] = useGlobal('user');
  const {auth} = useSelector(state => state.auth)

  let query = [];

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, email, phone';
    }

    let restData = await api
      .get(`/rest/user?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows
          // return data.result.rows.map((item, key) => {
          //   if (item.active)
          //     item.active = <div className="true-circle"></div>;
          //   else item.active = <div className="false-circle"></div>;

          //   item.key = key;
          //   return item;
          // });
        }
      });
    // restdata active true olanları setle
    let MyRestData = restData.filter((item) => item.active === true);
    setData(MyRestData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    // api.delete(`/rest/user/${item_id}`, ({ data }) => {});
    api.post("/rest/user/deleteUser", {  item_id:item_id}).then(({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/user/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let columns = [
    {
      title: "Ad Soyad",
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Email",
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email - b.email,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Telefon",
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
    },

    // {
    //   title: "Aktif",
    //   width: '50px',
    //   dataIndex: 'active',
    //   key: 'active',
    //   sorter: (a, b) => a.active - b.active,
    //   sortDirections: ['descend', 'ascend'], 
    // },
    {
      title: "İşlemler",
      key: 'action',
      align: 'center',
      width: 150,

      render: (text, record) => (
        <Space size="small">
          { !(auth && auth.find(a => a.number === user.role).restraintList.includes( "edit" )) && (
            <Link to={'/user/edit/' + record._id}>
              <Button 
                icon={<EditOutlined />}>
                  {!isSmall && 'Düzenle'}
              </Button>
            </Link>
          )}
          {!(auth && auth.find(a => a.number === user.role).restraintList.includes( "delete" )) && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={"Sil"}
              okText={"Sil"}
              cancelText={"İptal"}
            >
            <Button 
              type="danger" icon={<DeleteOutlined />}>
              {!isSmall && "Sil"}
            </Button>
          </Popconfirm>
          )}
          
        </Space>
      ),
    },
  ];



  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons py-5">
          {(!(auth && auth.find(a => a.number === user.role).restraintList.includes( "delete" )) && !isSmall) && 
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  size="large"
                  onClick={deleteSelectedRows}
                  style={{ fontSize: '14px', height: '100%', marginRight: '10px' }}
                >
                  Seçilenleri Sil
                </Button>
           }
         
          {!(auth && auth.find(a => a.number === user.role).restraintList.includes( "add" )) && (
            <Link to="/user/add" style={{ marginRight: '5px' }}>
              <Button
                type="light"
                style={{ fontSize: '14px', height: '100%' }}
                icon={<PlusOutlined />}
                size="large"
              >
                {!isSmall && "Kullanıcı Ekle"}
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Arama..."
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default User;
