import React, {useState,useMemo} from 'react';
import {Select} from 'antd';
import api from '../service/index'

export default (props) => {
    let {record, setRecord, name} = props;
    let [businessCategories,setBusinessCategories] = useState([]);
	let languagesCms = props.languagesCms;
	useMemo(() => {
		api.get(`/rest/businessCategories`).then(({ data }) => {
			if(data && data.result && data.result.rows.length>0){
				console.log(data.result.rows,"-----------------sdsdasda")
			let dbBusinessCategories = data.result.rows.sort((a,b) => a.name.localeCompare(b.name))
			setBusinessCategories(dbBusinessCategories);
			}
		})
	}, [])

    return <Select value={record[name]} placeholder={"Lütfen bir kategori seçiniz"} 
                onChange={v => setRecord({...record, [name]:v })}>
			    {(businessCategories) && businessCategories.map(b =>
				    <Select.Option key={b._id} value={b._id} >{b.name}</Select.Option>)}
		    </Select>
};