import React, { useEffect, useGlobal } from 'reactn';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import SideMenu from './layout/SideMenu';
import HeaderBar from './layout/HeaderBar';

import {
  Room,
  RoomDetail,
  Dashboard,
  User,
  UserDetail,
  NotFoundPage,
  Event,
  EventDetail,
  EventLocation,
  EventLocationDetail,
  EventReservation,
  EventReservationDetail,
  Notification,
  NotificationDetail,
  RoomLocation,
  RoomLocationDetail,
  RoomReservation,
  RoomReservationDetail,
  StaticNotification,
  StaticNotificationDetail,
  Settings,
  SettingsDetail,
  Place, 
  PlaceDetail,
  Plan, 
  PlanDetail,
  PlanCoordinates, 
  PlanReservation, 
  PlanReservationDetail,
  PlaceReport,
  PlanReservationReport,
} from './pages';
// import RoomReservation from './pages/RoomReservation';
// import RoomReservationDetail from './pages/RoomReservationDetail';
// import EventLocation from './pages/EventLocation';
// import EventLocationDetail from './pages/EventLocationDetail';
// import Notification from './pages/Notification';
// import NotificationDetail from './pages/NotificationDetail';
// import RoomLocation from './pages/RoomLocation';
// import RoomLocationDetail from './pages/RoomLocationDetail';
// import StaticNotification from './pages/StaticNotification';
// import StaticNotificationDetail from './pages/StaticNotificationDetail';

const { Header, Content } = Layout;

const Admin = (props) => {
  let [token, setToken] = useGlobal('token');
  let [user] = useGlobal('user');
  let [isSmall, setIsSmall] = useGlobal('isSmall');
  let [collapsed, setCollapsed] = useGlobal('collapsed');

  useEffect(() => {
    window.onresize = () => {
      setIsSmall(window.innerWidth < 1024);
    };
  }, [setIsSmall, setToken]);
  useEffect(() => {
    if (isSmall) setCollapsed(true);
    else setCollapsed(false);
  }, [isSmall, setCollapsed]);

  if (!token || !user) {
    props.history.push('/login');
  }

  return (
    <Switch>
      <Route>
        <Layout>
          <SideMenu collapsed={collapsed} />
          <Layout className="site-layout" style={{ minHeight: '100vh' }}>
            <Header
              className="site-layout-background header"
              style={{ padding: '0 20px' }}
              theme="dark"
            >
              <HeaderBar />
            </Header>

            <Content key="page" className="main-wrap">
              {token && (
                <>
                  <Switch>
                    <Route exact path="/user" component={User} />
                    <Route exact path="/user/edit/:id" component={UserDetail} />
                    <Route exact path="/user/add" component={UserDetail} />

                    <Route exact path="/room" component={Room} />
                    <Route exact path="/room/edit/:id" component={RoomDetail} />
                    <Route exact path="/room/add" component={RoomDetail} />

                    <Route
                      exact
                      path="/room-reservation/"
                      component={RoomReservation}
                    />
                    <Route
                      exact
                      path="/room-reservation/edit/:id"
                      component={RoomReservationDetail}
                    />
                    <Route
                      exact
                      path="/room-reservation/add"
                      component={RoomReservationDetail}
                    />

                    <Route exact path="/event" component={Event} />
                    <Route exact path="/event/edit/:id" component={EventDetail} />
                    <Route exact path="/event/add" component={EventDetail} />

                    <Route exact path="/event-location" component={EventLocation} />
                    <Route exact path="/event-location/edit/:id" component={EventLocationDetail} />
                    <Route exact path="/event-location/add" component={EventLocationDetail} />
                    <Route exact path="/event-reservation" component={EventReservation} />
                    <Route exact path="/event-reservation/edit/:id" component={EventReservationDetail} />
                    <Route exact path="/event-reservation/add" component={EventReservationDetail} />
                    
                    <Route exact path="/room-location" component={RoomLocation} />
                    <Route exact path="/room-location/edit/:id" component={RoomLocationDetail} />
                    <Route exact path="/room-location/add" component={RoomLocationDetail} />

                    <Route exact path="/notification" component={Notification} />
                    <Route exact path="/notification/edit/:id" component={NotificationDetail} />
                    <Route exact path="/notification/add" component={NotificationDetail} />

                    <Route exact path='/static-notification' component={StaticNotification} />
                    <Route exact path='/static-notification/edit/:id' component={StaticNotificationDetail} />
                    <Route exact path='/static-notification/add' component={StaticNotificationDetail} />

                    <Route exact path='/settings' component={Settings} />
                    <Route exact path='/settings/edit/:id' component={SettingsDetail} />
                    <Route exact path='/settings/add' component={SettingsDetail} />

                    <Route exact path="/place" component={Place} />
                    <Route exact path="/place/edit/:id" component={PlaceDetail} />
                    <Route exact path="/place/add" component={PlaceDetail} />

                    <Route exact path="/place/:placeId/plan" component={Plan} />
                    <Route exact path="/place/:placeId/plan/edit/:id" component={PlanDetail} />
                    <Route exact path="/place/:placeId/plan/add" component={PlanDetail} />

                    <Route exact path="/place/:placeId/plan/edit/:id/coordinates" component={PlanCoordinates} />
                    <Route exact path="/place/:placeId/plan/edit/:id/reservation" component={PlanReservation} />
                    <Route exact path="/planReservation/:id" component={PlanReservationDetail} />

                    <Route exact path="/placeReport" component={PlaceReport} />
                    <Route exact path="/planReservationReport" component={PlanReservationReport} />
                    
                    <Route path="*" component={NotFoundPage} />
                  </Switch>
                </>
              )}
            </Content>
          </Layout>
        </Layout>
      </Route>
    </Switch>
  );
};

export default Admin;
