import React, { useEffect, useState, useGlobal } from 'react';
import api from '../service/index';
import qs from 'qs';
import { Badge, Button, Popconfirm, Space, Table } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { turkishSort } from '../stores/utils';
import Spinner from '../components/Loading';
import moment from 'moment';

function EventReservation() {
  const [search, setSearch] = useState(false);
  const [roomReservations, setRoomReservations] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [rooms, setRooms] = useState([]);

  const query = [];

  let columns = [
    {
      title: 'Etkinlik',
      dataIndex: 'room',
      key: 'room',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Kullanıcı',
      dataIndex: 'user',
      key: 'user',
    },
    // {
    //   title: 'Başlangıç Saati',
    //   dataIndex: 'startTime',
    //   key: 'startTime',
    // },
    // {
    //   title: 'Bitiş Saati',
    //   dataIndex: 'endTime',
    //   key: 'endTime',
    // },
    {
      title: 'Ücret',
      dataIndex: 'totalprice',
      key: 'totalprice',
    },
    {
      title:"Bilet Sayısı",
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'Ücret Durumu',
      key: 'pay',
      render: (pay) =>
      pay.pay ? (
        <Badge
          className="site-badge-count-109"
          count="Ödendi"
          style={{
            backgroundColor: '#52c41a',
          }}
        />
      ) : (
        <Badge
          className="site-badge-count-109"
          count="Ödenmedi"
          style={{
            backgroundColor: '#f5222d',
          }}
        />
      ),
    },
    {
      title: 'İşlemler',
      key: 'action',
      align: 'center',
      render: (row) => (
        <div className="tbl-actions">
          <Space size="small">
            <Link to={'/event-reservation/edit/' + row._id}>
              <Button icon={<EditOutlined />}>Düzenle</Button>
            </Link>
            <Popconfirm
              onConfirm={() => deleteRow(row._id)}
              // onConfirm={() => console.log(row, 'row')}
              title={'Silme işlemini onaylıyor musunuz?'}
              okText={'Evet'}
              cancelText={'İptal'}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                Sil
              </Button>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  const get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 1000 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, email, phone';
    }

    function findUserName(userId) {
      const tmp = users.find((user) => user._id === userId);
      if (tmp && tmp.name) {
        return tmp.name;
      }
      return '-';
    }

    function findRoomName(roomId) {
      const tmp = rooms.find((room) => room._id === roomId);
      if (tmp && tmp.name) {
        return tmp.name;
      }
      return '-';
    }
    // get all users without pagination

    api
      .get(`/rest/eventReservation?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setTotalCount(data.result.total);
          console.log(data.result.rows, 'data.result.rows')
          setRoomReservations(
            data.result.rows.map((item, key) => ({
              ...item,
              key: key,
              room: findRoomName(item.eventId),
              user: findUserName(item.userId),
            })),
          );
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  const getRooms = () => {
    api
      .get(
        '/rest/event?' +
          qs.stringify({ page: 1, pageSize: 10000, sort: 'name' }),
      )
      .then(({ data: { result, result_message } }) => {
        if (result && result.rows) {
          setRooms(result.rows);
        }
      });
  };

  const getUsers = () => {
    api.post("/rest/user/getAllUsers",{}).then(({ data: { result, result_message } }) => {
      if (result ) {
        setUsers(result);
      }
    }).then(() => setLoading(false));

  };

  useEffect(() => {
    getRooms();
    getUsers();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (users.length && rooms.length) {
      get();
    }
  }, [users, rooms]);

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, rows) => {
      setSelectedRows(rows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const deleteRow = async (item_id) => {
    await api.delete(`/rest/eventReservation/${item_id}`, ({ data }) => {});
    get();
  };

  const deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/event/eventReservation/${item._id}`, ({ data }) => {});
      let newData = roomReservations.filter((el) => el._id !== item._id);
      setRoomReservations(newData);
      get();
    });
  };

  return (
    <div>
      {loading && <Spinner />}
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons py-5">
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            size="large"
            onClick={deleteSelectedRows}
            style={{
              fontSize: '14px',
              height: '100%',
              marginRight: '10px',
            }}
          >
            Seçilenleri Sil
          </Button>

          <Link to="/event-reservation/add" style={{ marginRight: '5px' }}>
            <Button
              type="light"
              style={{ fontSize: '14px', height: '100%' }}
              icon={<PlusOutlined />}
              size="large"
            >
              Rezervasyon Ekle
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-wrap">
        <Table
          dataSource={roomReservations}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
}

export default EventReservation;
