import {
  Button,
  Card,
  Collapse,
  DatePicker,
  message,
  Switch,
  Form,
  Col,
  Row,
  Input,
  ConfigProvider
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../service';
import { LeftOutlined } from '@ant-design/icons';
import qs from 'qs';
import { AttendeePicker2, SeatPickers } from '../components';
import { RoomPicker } from '../components';
import { useCallback } from 'react';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
moment.locale('tr');
const { RangePicker } = DatePicker;

function RoomReservationDetail() {

  let newRecord = {
    room: {},
    user: {},
    totalprice: '',
    pay: false,
    date: '',
    startTime: '',
    endTime: '',
    currency: 'TL',
    slots: [],
    userId: '',
    roomId: '',
  }




  const params = useParams();
  const history = useHistory();
  const id = params.id !== 'add' ? params.id : false;
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [validationCheck, setValidationCheck] = useState(false);
  const [loading, setLoading] = useState(id ? true : false);
  // const [startDate, setStartDate] = useState();
  const [users, setUsers] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [slots, setSlots] = useState([]);
  const { Panel } = Collapse;



  useEffect(() => {
    getUsers();
    getRooms();
  }, []);



  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get('/rest/roomReservation/' + id).then(({ data: { result, result_message } }) => {
        if (result) {
          setData(result);
          setLoading(false);
        }
          // setData(result);
          // setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  const getSlots = (roomId, currentSlots) => {

    function findSelected(start) {
      const tmp = currentSlots.find((i) => i.time === start);
      if (tmp) {
        return true;
      }
      return false;
    }
    api
      .get('/rest/roomslots/' + roomId)
      .then(({ data: { result, result_message } }) => {
        if (result && result.times) {
          setSlots(
            result.times.map((i) => ({
              ...i,
              selected: findSelected(i.start),
            })),
          );
        }
      });
  };

  const getAddress = (addressId) => {
    api
      .get('/rest/userAddress/' + addressId)
      .then(({ data: { result, result_message } }) => {});
  };
  const getRooms = () => {
    api
      .get(
        '/rest/room?' +
          qs.stringify({ page: 1, pageSize: 10000, sort: 'name' }),
      )
      .then(({ data: { result, result_message } }) => {
        setRooms(
          result.rows.map((i) => ({
            value: i._id,
            label: i.name,
          })),
        );
      });
  };

  const getUsers = () => {
    api
      .get(
        '/rest/user?' +
          qs.stringify({ page: 1, pageSize: 10000, sort: 'name' }),
      )
      .then(({ data: { result, result_message } }) => {
        setUsers(
          result.rows.map((i) => ({
            value: i._id,
            label: i.name,
          })),
        );
      });
  };

  const addNewReservation = () => {
    const tmpReservation = {
      id: reservations.length + 1,
      startTime: null,
      endTime: null,
      time: null,
      userId: null,
      roomId: null,
      date: null,
    };
    setReservations([...reservations, tmpReservation]);
  };


  const save = () => {
    // data.userId = data.user._id;
    // data.roomId = data.room._id;
    data.slots[0] = {
      startTime: data.startDate,
      endTime: data.endDate,
      userId : data.userId,
      roomId : data.roomId,
      date : data.date,
      time : data.time,
    }
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api.put('/rest/roomReservation/' + id, data)
        .then(({ data: { result, result_message } }) => { 
          if (result_message.type === 'success') {
            message.success('Rezervasyon başarıyla güncellendi', 3);
            history.push('/room-reservation');
          } else {
            message.error('Rezervasyon güncellenemedi', 3);
          }
        });
      } else {
        api.post('/rest/roomReservation', data)
        .then(({ data: { result, result_message } }) => { 
          if (result_message.type === 'success') {
            message.success('Rezervasyon başarıyla eklendi', 3);
            history.push('/room-reservation');
          } else {
            message.error('Rezervasyon eklenemedi', 3);
          }
        });
      }
    }
          
  };

  // const updatedReservation = (data) => {
  //   setReservations(
  //     reservations.map((item) => {
  //       if (item.id === data.id) {
  //         return { ...item, ...data };
  //       }
  //       return item;
  //     }),
  //   );
  // };

  // const onDeleteSlot = (slotId) => {
  //   setReservations([]);
  //   if (slotId) {
  //     setTimeout(() => {
  //       setReservations(reservations.filter((item) => item.id !== slotId));
  //     }, 0);
  //   }
  // };



  // const onStartDateSelected = (value, dateString) => {
  //   setStartDate(moment(value, 'dd-mm-yyyy HH:mm'));
  // };

  const setSlot = (slot) => {
    if (slot.expired) {
      return;
    }
    setSlots(
      slots.map((i) => {
        if (i.start === slot.start) {
          return {
            ...i,
            selected: !slot.selected,
          };
        }
        return i;
      }),
    );
  };


  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name: ""}</h1>
        </div>
        <div className="list-buttons">
          <div className="list-buttons py-5">
            <Link to="/room-reservation">
              <Button 
                type='danger'
                icon={<LeftOutlined />} 
                style={{
                  fontSize: '14px',
                  height: '100%',
                  marginRight: '10px',
                }}
                >
                  Geri
                </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={ id ? "Rezervasyon Düzenle" : "Rezervasyon Ekle"} loading={loading}>
          <Form layout="horizontal" size="large" onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Oda"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <RoomPicker
                    name="roomId"
                    record={data}
                    setRecord={setData}
                    onChange={(e) => setData({ ...data, roomId: e })}
                    placeholder="Oda Seçiniz"
                    />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kullanıcı"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <AttendeePicker2
                    name="userId"
                    record={data}
                    setRecord={setData}
                    onChange={(e) => setData({ ...data, userId: e })}
                    placeholder="Kullanıcı Seçiniz"
                    />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Oda Ücreti"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="totalprice"
                    value={data.totalprice}
                    onChange={(e) => setData({ ...data, totalprice: e.target.value })}
                    placeholder="Oda Ücreti"
                    type="number"
                    />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Ödeme Durumu"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Switch
                    name="pay"
                    checked={data.pay}
                    checkedChildren="Ödendi"
                    unCheckedChildren="Ödenmedi"
                    onChange={(e) => setData({ ...data, pay: e })}
                    />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Başlangıç Tarihi"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <DatePicker
                    name="startTime"
                    defaultValue={()=>moment(data.startTime,"DD-MM-YYYY HH:mm")}
                    value={data.startTime ? moment(data.startTime,"DD-MM-YYYY HH:mm") : null}
                    onChange={(e) => setData({ ...data, startTime: e })}
                    placeholder={"Başlangıç Tarihi"}
                    allowClear={true}
                    format="DD-MM-YYYY HH:mm"
                    showTime={{ format: 'HH:mm' }}
                    locale={locale}
                    minuteStep={30}
                    disabledHours={ () => [0, 1, 2, 3, 4, 5, 6, 7, 8,23] }
                    showNow={false}
                    />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Bitiş Tarihi"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <DatePicker
                    name="endTime"
                    defaultValue={()=>moment(data.endTime,"DD-MM-YYYY HH:mm")}
                    value={data.endTime ? moment(data.endTime,"DD-MM-YYYY HH:mm") : null}
                    onChange={(e) => setData({ ...data, endTime: e })}
                    placeholder={"Bitiş Tarihi"}
                    allowClear={true}
                    format="DD-MM-YYYY HH:mm"
                    showTime={{ format: 'HH:mm' }}
                    locale={locale}
                    minuteStep={30}
                    disabledHours={ () => [0, 1, 2, 3, 4, 5, 6, 7, 8,23] }
                    showNow={false}
                    />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {"Kaydet"}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          
        </Card>
      </div>
    </div>
  );
}


export default RoomReservationDetail;
