import React from 'react';
import { useGlobal } from 'reactn';
import Cookies from 'js-cookie';
import {MdClose, MdLogout } from 'react-icons/md'
import { Button } from 'antd';

const HeaderBar = (props) => {
  let [, setToken] = useGlobal('token');


  let logout = (props) => {
    Cookies.remove('token');
    window.localStorage.clear();
    setToken(null);
  };

  return (
    <div className="topMenu">
        <Button type="danger" onClick={logout}>Çıkış yap</Button>
    </div>
  );
};

export default HeaderBar;
